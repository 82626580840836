import React, { useState, useRef, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import Container from "@mui/material/Container";
import "./styles.css";

// Add this interface near the top of the file, after imports
interface CamItem {
  username: string;
  age: number;
  display_name: string;
  image_url_360x270: string;
  chat_room_url_revshare: string;
}

// Add this mock data
const mockData = {
  lastUpdated: new Date().toISOString(),
  cams: [
    {
      username: "user1",
      age: 21,
      display_name: "Test User 1",
      image_url_360x270: "https://picsum.photos/360/270",
      chat_room_url_revshare: "https://example.com/1",
    },
    {
      username: "user2",
      age: 22,
      display_name: "Test User 2",
      image_url_360x270: "https://picsum.photos/360/270",
      chat_room_url_revshare: "https://example.com/2",
    },
  ],
};

// Add type definition for umami
declare global {
  interface Window {
    umami?: {
      track: (eventName: string, eventData?: object) => void;
    };
  }
}

export default function CamList(): JSX.Element {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const { isLoading, error, data } = useQuery(
    "repoData",
    () =>
      process.env.NODE_ENV === "development"
        ? Promise.resolve(mockData)
        : fetch("https://chixxx.online/cams-api/").then((res) => res.json()),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    }
  );

  const handleNavClick = useCallback(
    (direction: "up" | "down") => {
      if (!data) return;

      const newIndex = direction === "up" ? currentIndex - 1 : currentIndex + 1;

      if (newIndex >= 0 && newIndex < data.cams.length) {
        containerRef.current?.scrollTo({
          top: newIndex * containerRef.current.clientHeight,
          behavior: "smooth",
        });

        setCurrentIndex(newIndex);

        if (process.env.NODE_ENV === "production" && window.umami) {
          window.umami.track("nav_click", {
            direction,
            fromIndex: currentIndex,
            toIndex: newIndex,
          });
        }
      }
    },
    [currentIndex, data]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowUp") {
        handleNavClick("up");
      } else if (event.key === "ArrowDown") {
        handleNavClick("down");
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleNavClick]);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && data) {
        const scrollPosition = containerRef.current.scrollTop;
        const itemHeight = containerRef.current.clientHeight;

        const newIndex = Math.floor(scrollPosition / itemHeight);
        if (newIndex >= 0 && newIndex < data.cams.length) {
          setCurrentIndex(newIndex);

          if (process.env.NODE_ENV === "production" && window.umami) {
            window.umami.track("scroll", {
              direction:
                scrollPosition > currentIndex * itemHeight ? "down" : "up",
              toIndex: newIndex,
              username: data.cams[newIndex].Username,
            });
          }
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [currentIndex, data]);

  if (isLoading) return <p>'Loading...'</p>;
  if (error instanceof Error && error)
    return <p>{"An error has occurred: " + error.message}</p>;

  const theme = createTheme({
    palette: {
      primary: { main: "#212121" },
      secondary: { main: "#ff4081" },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        maxWidth="sm"
        disableGutters
        sx={{ height: "100vh", bgcolor: "#000", position: "relative" }}
      >
        <AppBar
          position="fixed"
          color="primary"
          sx={{
            top: 0,
            bottom: "auto",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            maxWidth: "sm",
          }}
        >
          <Typography
            variant="h6"
            noWrap
            sx={{
              ml: 2,
              mr: 2,
              fontFamily: "Anton",
              // letterSpacing: '.3rem',
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Chixxx Online
          </Typography>
          <Typography
            noWrap
            sx={{
              ml: 2,
              mr: 2,
              fontFamily: "Bubbler One",
              // letterSpacing: '.3rem',
              color: "inherit",
              textDecoration: "none",
            }}
          >
            The hottest teen girls on Chaturbate
          </Typography>
        </AppBar>

        <Box
          ref={containerRef}
          sx={{
            height: "100%",
            overflowY: "scroll",
            scrollSnapType: "y mandatory",
            overscrollBehavior: "contain",
          }}
        >
          {data.cams.map((item: CamItem) => (
            <Card
              key={item.username}
              sx={{
                height: "100%",
                scrollSnapAlign: "start",
                position: "relative",
                bgcolor: "#000",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 64,
                  left: 0,
                  right: 0,
                  background: "linear-gradient(rgba(0,0,0,0.8), transparent)",
                  padding: 2,
                  color: "white",
                  zIndex: 1,
                }}
              >
                <Typography variant="h6">{item.username}</Typography>
                <Typography variant="body2">Age: {item.age}</Typography>
                <Typography variant="body2">{item.display_name}</Typography>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CardMedia
                  component="img"
                  image={item.image_url_360x270}
                  alt={item.username}
                  sx={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                  onClick={() => {
                    if (window.umami) {
                      window.umami.track("image_click", {
                        username: item.username,
                      });
                    }
                    window.location.href = item.chat_room_url_revshare;
                  }}
                />
              </Box>
            </Card>
          ))}
        </Box>

        <AppBar
          position="fixed"
          sx={{
            top: "auto",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            maxWidth: "sm",
            bgcolor: "transparent", // Make AppBar transparent
            boxShadow: "none", // Remove shadow
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <IconButton color="inherit" onClick={() => handleNavClick("up")}>
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              color="secondary"
              onClick={() => {
                if (window.umami) {
                  window.umami.track("open_chat", {
                    username: data.cams[currentIndex].username,
                    fromIndex: currentIndex,
                  });
                }
                window.location.href =
                  data.cams[currentIndex].chat_room_url_revshare;
              }}
            >
              <OpenInBrowserIcon />
            </IconButton>
            <IconButton color="inherit" onClick={() => handleNavClick("down")}>
              <ArrowDownwardIcon />
            </IconButton>
          </Box>
        </AppBar>
      </Container>
    </ThemeProvider>
  );
}
